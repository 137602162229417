import { EnvironmentInjector } from '@angular/core';

import type { IUserDetailsTabComponent, IUserDetailsTabPlugin, LazyComponentDetails } from '@mpk/shared/util';

export const userTabPlugin: IUserDetailsTabPlugin = {
  label: 'Kostenstellen',

  async getComponentDetails(
    parentInjector: EnvironmentInjector,
  ): Promise<LazyComponentDetails<IUserDetailsTabComponent>> {
    const userCostCentersAssignmentFeature = await import(
      '@mp/organization-master-data/user-cost-centers-assignment/feature'
    );

    return {
      component: userCostCentersAssignmentFeature.UserCostCentersAssignmentComponent,
      environmentInjector: parentInjector,
    };
  },
};
